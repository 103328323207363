/*
 * @Description: 
 * @version: 0.01
 * @Company: DCIT-SH
 * @Author: guohl
 * @Date: 2022-03-22 18:05:33
 * @LastEditors: guohl
 * @LastEditTime: 2022-03-22 18:25:28
 */
module.exports = {
    'oss_domain_fengdie' : './',
};
